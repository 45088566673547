import {groq} from 'next-sanity'
import {client} from '../lib/client'
import ImageUrlBuilder from '@sanity/image-url'

export async function getPosts() {
  const myClient = client

  return myClient.fetch(
    groq`*[_type == "post"] | order(publishedAt desc) {
      _id,
      _createdAt,
      title,
      publishedAt,
      "slug": slug.current,
      "image": mainImage.asset -> url,
      "categories": categories[]->{
        title,
        _key,
        "slug": slug.current
      },
      description,
      body
    }`
  )
}


export async function getConcepts() {
  const myClient = client

  return myClient.fetch(
    groq`*[_type == "concept" && isSelected == true]{
      _id,
      title,
      "slug": slug.current,
      description,
      isSelected
    } | order(title asc)`
  )
}

export async function getPost(slug) {
  try {
    const myClient = client

    const post = await myClient
      .fetch(
        groq`*[_type == "post" && slug.current == $slug][0]{
        _id,
        _createdAt,
        title,
        publishedAt,
        "slug": slug.current,
        "image": mainImage.asset -> url,
        "categories": categories[]->{
          title,
          _key,
          "slug": slug.current
        },
        description,
        "body": body[]{
          ...,
          "children": children[]{..., "marks": marks[]},
          "markDefs": markDefs[]{
            ...,
            "concept": concept-> {
              _type,
              _id,
              title,
              "slug": slug.current
            }
          }  
        }
      }`,
        {slug}
      )
      .catch((e) => console.error(e))

    return post
  } catch (error) {
    console.error('Error fetching post: ', error)
    return null
  }
}


export async function getLastPost() {
  try {
    const myClient = client

    const post = await myClient
      .fetch(
        groq`*[_type == "post"] | order(publishedAt desc)[0]{
        _id,
        _createdAt,
        title,
        publishedAt,
        "slug": slug.current,
        "image": mainImage.asset -> url,
        "categories": categories[]->{
          title,
          _key,
          "slug": slug.current
        },
        description,
        body
      }`
      )
      .catch((e) => console.error(e))

    return post
  } catch (error) {
    console.error('Error fetching post: ', error)
    return null
  }
}


export async function getPage(slug) {
  try {
    const myClient = client

    const page = await myClient
      .fetch(
        groq`*[_type == "page" && slug.current == $slug][0]{
        _id,
        _createdAt,
        title,
        publishedAt,
        "slug": slug.current,
        "image": mainImage.asset -> url,
        "categories": categories[]->{
          _key,
          title,
          "slug": slug.current
        },
        description,
        "body": body[]{
          ...,
          "children": children[]{..., "marks": marks[]},
          "markDefs": markDefs[]{
            ...,
            "concept": concept-> {
              _type,
              _id,
              title,
              "slug": slug.current
            }
          }  
        }
      }`,
        {slug}
      )
      .catch((e) => console.error(e))

    return page
  } catch (error) {
    console.error('Error fetching page: ', error)
    return null
  }
}


// Function to fetch pages by category with an optional limit
export async function getPagesByCategory(category, limit = null, includeTotalCount = false) {
  try {
    const myClient = client

    let query = groq`*[_type == "page" && $category in categories[]->slug.current]{
      _id,
      _createdAt,
      title,
      publishedAt,
      "slug": slug.current,
      "image": mainImage.asset -> url,
      "categories": categories[]->{
        _key,
        title,
        "slug": slug.current
      },
      description,
      "body": body[]{
        ...,
        "children": children[]{..., "marks": marks[]},
        "markDefs": markDefs[]{
          ...,
          "concept": concept-> {
            _type,
            _id,
            title,
            "slug": slug.current
          }
        }  
      }
    }`

    // Query to get the count of pages in the category
    let countQuery = groq`count(*[_type == "page" && $category in categories[]->slug.current])`

    if (limit) {
      query += `[0...${limit}]`
    }

    const pages = await myClient
      .fetch(query, {category})
      .catch((e) => console.error(e))

    if (includeTotalCount) {
      const totalCount = await myClient
        .fetch(countQuery, {category})
        .catch((e) => console.error(e))

      return {pages, totalCount}
    }

    return pages;

  } catch (error) {
    console.error('Error fetching pages: ', error)
    return null
  }
}


export async function getConcept(slug) {
  try {
    const myClient = client

    const concept = await myClient
      .fetch(
        groq`*[_type == "concept" && slug.current == $slug][0]{
        _id,
        title,
        "slug": slug.current,
        description,
      }`,
        {slug}
      )
      .catch((e) => console.error(e))

    return concept
  } catch (error) {
    console.error('Error fetching concept: ', error)
    return null
  }
}


export async function getAnnouncement(slug) {
  try {
    const myClient = client

    const concept = await myClient
      .fetch(
        groq`*[_type == "announcement" && slug.current == $slug][0]{
        _id,
        title,
        ribbon,
        url,
        "slug": slug.current,
        description,
      }`,
        {slug}
      )
      .catch((e) => console.error(e))

    return concept
  } catch (error) {
    console.error('Error fetching concept: ', error)
    return null
  }
}

export async function getTeam() {
  const myClient = client

  return myClient.fetch(
    groq`*[_type == "team"]{
      _id,
      name,
      "slug": slug.current,
      role,
      image,
      bio,
      position,
    } | order(position asc)`
  )
}


const builder = ImageUrlBuilder(client)

export function urlFor(source) {
  return builder.image(source)
}
