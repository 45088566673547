import React from 'react';
import Link from 'next/link';
import {useEffect, useState} from "react";
import {getPagesByCategory} from "@/sanity/utils/sanity-utils";

import {motion} from "framer-motion";
import {FiArrowUpRight} from 'react-icons/fi';
import styles from "@/components/navhub/v2/NavHub.module.css";

export default function NavCategory({title, category, limit = null, custom}) {
  const [categoryPages, setCategoryPages] = useState([]);
  const [remainingCount, setRemainingCount] = useState(0);

  const variants = {
    hidden: {opacity: 0, y: '5%'},
    visible: (i) => ({opacity: 1, y: 0, transition: {duration: 0.5, delay: i * 0.2}}),
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const {pages, totalCount} = await getPagesByCategory(category, limit, true);
        setCategoryPages(pages);
        setRemainingCount(totalCount - pages.length); // Calculate remaining articles
      } catch (error) {
        console.error('Error fetching category pages: ', error);
      }
    };

    fetchCategory();
  }, [category, limit]);

  // Function to return the correct label for the number of articles
  const moreLabel = (count) => {
    if (count === 1) {
      return `${count} More Article`;
    } else {
      return `${count} More Articles`;
    }
  };

  return (
    <motion.div
      key={custom}
      className={styles.category}
      initial="hidden"
      animate="visible"
      variants={variants}
      custom={custom}
    >
      <div className={styles.headerElement}>
        <div className={styles.iconTitle}>
          {title}
        </div>
      </div>
      {categoryPages.map((page, index) => (
        <motion.div
          key={index}
          className={styles.subcategory}
          initial="hidden"
          animate="visible"
          variants={variants}
          custom={index}
        >
          <Link key={page._id} href={`/knowledge/${page.slug}`}>
            <div className={styles.headerElement}>
              <div className={styles.iconTitle}>
                {page.title}
              </div>
            </div>
          </Link>
        </motion.div>
      ))}

      {remainingCount > 0 && ( // Conditional rendering
        <Link className={styles.moreA} href='/knowledge'>
          <div className={styles.moreLink}>
            <span className={styles.moreLabel}>{moreLabel(remainingCount)}</span>
            <span className={styles.moreIcon}><FiArrowUpRight/></span>
          </div>
        </Link>
      )}

    </motion.div>
  );
}
