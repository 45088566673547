import { server } from "@/utils/Config";

export async function trackEvent(
  event_name,
  category,
  label,
  user_id,
  user_agent = null,
  ip = null,
  url = null,
  screenHeight = null,
  screenWidth = null
) {
  try {
    const url = typeof window !== "undefined" ? window.location.href : null;
    const screenHeight =
      typeof window !== "undefined" ? window.innerHeight : null;
    const screenWidth =
      typeof window !== "undefined" ? window.innerWidth : null;

    console.log(
      "Tracking event:",
      event_name,
      category,
      label,
      user_id,
      user_agent,
      ip,
      url,
      screenHeight,
      screenWidth
    );
    const response = await fetch(`${server}/api/trackEvent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event_name,
        category,
        label,
        user_id,
        user_agent,
        ip,
        url,
        screenHeight,
        screenWidth,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("DATA_", data.message);
    } else {
      throw new Error("Error tracking event");
    }
  } catch (error) {
    console.error("Error tracking event:", error);
  }
}

export async function pageView(url, user_id, user_agent, ip) {
  await trackEvent("Page View", "Navigation", url, user_id, user_agent, ip);
}
