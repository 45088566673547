import {createClient} from 'next-sanity'
//import { Observable } from 'rxjs';

export const client = createClient({
  apiVersion: '2023-05-12',
  projectId: 'ok3slgjy',
  dataset: 'production',
  useCdn: false,
  //middleware: [Observable],
})
