// components/LinkScroll.js
import Link from "next/link";
import React from "react";

// component definition
const LinkScroll = ({ children, onClick, ...props }) => {
  const handleScroll = (e) => {
    e.preventDefault();
    // remove everything before the hash
    const targetId = e.currentTarget.href.replace(/.*\#/, "");
    const elem = document.getElementById(targetId);
    const currentScrollPosition = window.pageYOffset;
    const targetPosition =
      elem?.getBoundingClientRect().top + currentScrollPosition;
    window.scrollTo({
      top: targetPosition - 80,
      behavior: "smooth",
    });

    if (typeof onClick === "function") {
      onClick(e);
    }
  };

  return (
    <Link {...props} onClick={handleScroll}>
      {children}
    </Link>
  );
};

export default LinkScroll;
